/*
 * WARNING: This file is owned by ws-scripts and can not be edited!
 *
 * This is the main entry point for our application when
 * executed in the browser. As such, it has some responsibilities:
 *
 * - Load server side rendered redux state
 * - Create the redux store with that state
 * - Kick off a React hydrate bound to the redux store
 *
 * We load our server side rendered state from an object
 * of the form:
 *
 *	window.DDC = {
 *		WS: {
 *			state: {
 *				"ws-hours": {
 *					"hours1": {STATE}
 *					"hours2": {STATE}
 *				},
 *				"ws-quick-specs": {
 *					"quick-specs1": {STATE}
 *				}
 *			}
 *		}
 *	};
 *
 * In local development, the state will be a JSON object. When
 * loaded from CMS, it will be a string, since that reduces
 * serialization overhead but still avoids string concatenation.
 */

import { hydrate } from 'react-dom';
import WidgetWrapper from './components/layout/WidgetWrapper';
import { createStore } from './store';

const getWidgetStatesByType = (widgetName) => {
	if (
		!window.DDC ||
		!window.DDC.WS ||
		!window.DDC.WS.state ||
		!window.DDC.WS.state[widgetName]
	) {
		return {};
	}

	return window.DDC.WS.state[widgetName];
};

const getWidgetState = (widgetStates, widgetId) => {
	let preloadedState = widgetStates[widgetId];

	if (typeof preloadedState === 'string') {
		preloadedState = JSON.parse(preloadedState);
	}

	return preloadedState;
};

const widgetIdToStore = {};

const initializeWidgets = () => {
	const widgetStates = getWidgetStatesByType('ws-dealer-services');
	const widgetIds = Object.keys(widgetStates);

	widgetIds.forEach((widgetId) => {
		const store = createStore(getWidgetState(widgetStates, widgetId));
		widgetIdToStore[widgetId] = store;

		// When the widget is initially rendered server-side, render.jsx passes outputWrapperDiv
		// to render the widget wrapper div. Here, when hydrating, we pass outputWrapperDiv=false.
		// This is because hydrate takes a DOM element to hydrate into. We can't wrap the widget
		// in an extra div to target because there is a lot of styling in our system that
		// breaks. So, instead, we have to target the widget wrapper div and hydrate the
		// components inside of it.
		hydrate(
			<WidgetWrapper
				outputWrapperDiv={false}
				store={store}
				widgetId={widgetId}
			/>,
			document.getElementById(`${widgetId}-app-root`)
		);

		// We've initialized. We don't need to keep this data around anymore.
		// Deleting it ensures that we don't accidentally reinitialize later.
		delete widgetStates[widgetId];
	});
};

initializeWidgets();
